<template>
    <div>
        <div ref="chart" class="chart"></div>
    </div>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts'

    export default {
        name: "App",
        props: {
            // type: {
            //     type: String,
            //     required: true
            // }
        },

        mounted() {
            // code from Example: https://apexcharts.com/javascript-chart-demos/area-charts/spline/
            var options = {
                chart: {
                    type: 'area',
                    width: '100%',
                    height: '100%',
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: "smooth"
                },
                series: [
                    {
                        name: "series1",
                        data: [31, 40, 28, 51, 42, 109, 100]
                    },
                    {
                        name: "series2",
                        data: [11, 32, 45, 32, 34, 52, 41]
                    }
                ],

                xaxis: {
                    type: "datetime",
                    categories: [
                        "2018-09-19T00:00:00",
                        "2018-09-19T01:30:00",
                        "2018-09-19T02:30:00",
                        "2018-09-19T03:30:00",
                        "2018-09-19T04:30:00",
                        "2018-09-19T05:30:00",
                        "2018-09-19T06:30:00"
                    ]
                },
                tooltip: {
                    x: {
                        format: "dd/MM/yy HH:mm"
                    }
                },
                theme: {
                    mode: 'light',
                    palette: 'palette3'
                }
            };

            if (this.$refs.chart) {
                // HTML element exists
                var chart = new ApexCharts(this.$refs.chart, options);
                chart.render();
            }
        }
    };
</script>

