// import export default libs
import axios from 'axios';
import Vue from 'vue';

// set axios on settings on window ?
window.axios = axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest'
};

// Set to false before PROD
Vue.config.devtools = false;

Vue.component('area-chart', require('./components/AreaChart.vue').default);
Vue.component('bar-chart', require('./components/BarChart.vue').default);
Vue.component('bar-chart-axios', require('./components/BarChartAxios.vue').default);
Vue.component('donut-chart', require('./components/DonutChart.vue').default);
Vue.component('donut-chart-axios', require('./components/DonutChartAxios.vue').default);
Vue.component('line-chart', require('./components/LineChart.vue').default);
Vue.component('pie-chart-axios', require('./components/PieChartAxios.vue').default);
Vue.component('sparkline-chart', require('./components/SparklineChart.vue').default);

if (document.querySelector('body')) {
    const app = window.app = new Vue({
        el: '#vueapp',
    });
}

