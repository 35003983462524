<template>
    <div>
        <div ref="chart" class="chart">
            <apexchart
                type=donut
                height="460"
                :options="chartOptions"
                :series="series"
            />
        </div>
    </div>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';
    import axios from 'axios';

    export default {
        name: 'donut-chart',
        props: {
            endpoint: {
                type: String,
                required: true
            },
            money: {
                type: Boolean,
                required: false
            }
        },
        data: () => ({
            series: [
                1,1 // Dummy data: without it, there's no animation
            ],
            chartOptions: {
                series: [],
                labels: [],
                colors: [],

                chart: {

                    animations: {
                        enabled: true,
                        easing: 'easin',
                        speed: 1000,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 1000
                        }
                    },

                    toolbar: {
                        show: false, // Display hamburger icon with saving options
                    },
                    events: {
                        dataPointSelection: (e, chartContext, config) => {
                            // you can call Vue methods now as "this" will point to the Vue instance when you use ES6 arrow function
                            // this.VueDemoMethod();
                            // console.log(chartContext);
                            // console.log(config);
                            console.log(config.w.config.series[config.dataPointIndex])
                            console.log(config.w.config.labels[config.dataPointIndex])
                        },
                        click: (event, chartContext, config) => {
                            // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
                            // console.log('Click'); // TODO: remove before PROD release
                            // console.log(chartContext); // TODO: remove before PROD release
                            // console.log(config); // TODO: remove before PROD release
                        }
                        // click: function(event, chartContext, config) {
                        //     console.log('clicked');
                        //     // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
                        // }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                tooltip: {
                    enabled: true,
                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                        let total = 0;
                        for (let x of series) {
                            total += x;
                        }
                        let selected = series[seriesIndex];
                        return w.config.labels[seriesIndex] + ": " + selected + " (" + (selected / total * 100).toFixed(2) + "%)";
                    }
                },
                // responsive: [
                //     {
                //         breakpoint: 480,
                //         options: {
                //             chart: {
                //                 width: 200
                //             },
                //             legend: {
                //                 show: false
                //             }
                //         }
                //     },
                //     {
                //         breakpoint: 1280,
                //         options: {
                //             chart: {
                //                 width: 480
                //             },
                //         }
                //     }],
                legend: {
                    position: 'bottom',
                    offsetY: 0,
                    // height: 230
                }
            }
        }),
        components: {
            apexchart: VueApexCharts,
        },
        methods: {

            getPoints() {
                const path = this.endpoint;
                axios.get(path)
                    .then((res) => {
                        this.chartOptions = {
                            series: res.data.series,
                            labels: res.data.labels,
                            colors: res.data.colors
                        };
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
        },
        created(){
            this.getPoints();

            if(this.money) {
                this.chartOptions.tooltip = {
                    enabled: true,
                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                        let total = 0;
                        for (let x of series) {
                            total += x;
                        }
                        let selected = series[seriesIndex];
                        let amount = formatMoney(selected);

                        return w.config.labels[seriesIndex] + ": (" + (selected / total * 100).toFixed(2) + "%)";
                        // WITH MONEY return w.config.labels[seriesIndex] + ": " + amount + " (" + (selected / total * 100).toFixed(2) + "%)";
                    }
                }
            }
        },
    }

    function formatMoney(number) {
        return number.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' });
    }

</script>
