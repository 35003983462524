<template>
    <div>
        <div ref="chart" class="chart"></div>
    </div>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts'

    export default {
        name: "App",
        props: {
            // type: {
            //     type: String,
            //     required: true
            // }
        },

        mounted() {
            // code from Example: https://apexcharts.com/javascript-chart-demos/area-charts/spline/
            var options = {
                chart: {
                    type: 'area',
                    width: '100%',
                    height: '100%',
                    toolbar: {
                        show: false
                    },
                    sparkline: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: "straight"
                },
                fill: {
                    opacity: 0.3
                },
                // colors: ['#DCE6EC'], // gray
                series: [
                    {
                        name: "series1",
                        data: [31, 40, 28, 51, 42, 109, 100]
                    },
                    {
                        name: "series2",
                        data: [11, 32, 45, 32, 34, 52, 41]
                    }
                ],

                xaxis: {
                    crosshairs: {
                        width: 1
                    },
                },
                yaxis: {
                    min: 0
                },
                title: {
                    text: '€ 135.965',
                    offsetX: 0,
                    style: {
                        fontSize: '24px',
                    }
                },
                subtitle: {
                    text: 'Cofinanciering',
                    offsetX: 0,
                    style: {
                        fontSize: '14px',
                    }
                }
            };

            if (this.$refs.chart) {
                // HTML element exists
                var chart = new ApexCharts(this.$refs.chart, options);
                chart.render();
            }
        }
    };
</script>

