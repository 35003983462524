<template>
    <div>
        <div ref="chart" class="chart"></div>
    </div>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts'

    export default {
        name: "App",
        props: {
            // type: {
            //     type: String,
            //     required: true
            // }
        },

        mounted() {
            // code from Example: https://apexcharts.com/javascript-chart-demos/area-charts/spline/
            var options = {
                chart: {
                    type: 'donut',
                    width: '100%',
                    height: 240,
                    toolbar: {
                        show: false
                    },
                    sparkline: {
                        enabled: true
                    },
                    animations: {
                        enabled: true
                    },
                },
                legend: {
                    show: true,
                },
                series: [44, 55, 41, 17, 15],
                colors: ["#206bc4", "#79a6dc", "#bfe399", "#e9ecf1"],

                // dataLabels: {
                //     enabled: false
                // },
                // stroke: {
                //     curve: "smooth"
                // },
                // series: [
                //     {
                //         name: "series1",
                //         data: [31, 40, 28, 51, 42, 109, 100]
                //     },
                //     {
                //         name: "series2",
                //         data: [11, 32, 45, 32, 34, 52, 41]
                //     }
                // ],
                //
                // xaxis: {
                //     type: "datetime",
                //     categories: [
                //         "2018-09-19T00:00:00",
                //         "2018-09-19T01:30:00",
                //         "2018-09-19T02:30:00",
                //         "2018-09-19T03:30:00",
                //         "2018-09-19T04:30:00",
                //         "2018-09-19T05:30:00",
                //         "2018-09-19T06:30:00"
                //     ]
                // },
                tooltip: {
                    x: {
                        format: "dd/MM/yy HH:mm"
                    },
                    fillSeriesColor: false
                }
            };

            if (this.$refs.chart) {
                // HTML element exists
                var chart = new ApexCharts(this.$refs.chart, options);
                chart.render();
            }
        }
    };
</script>

