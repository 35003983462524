<template>
    <div>
        <div ref="chart" class="chart">
            <apexchart
                type=bar
                height="460"
                :options="chartOptions"
                :series="series"
            />
        </div>
    </div>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';
    import axios from 'axios';

    export default {
        name: "bar-chart",
        props: {
            endpoint: {
                type: String,
                required: true
            },
            money: {
                type: Boolean,
                required: false
            }
        },
        data: () => ({
            series: [],
            chartOptions: {
                series: [],
                labels: [],
                colors: [
                    "#ED5565",
                    "#FC6E51",
                    "#FFCE54",
                    "#A0D468",
                    "#48CFAD",
                    "#4FC1E9",
                    "#5D9CEC",
                    "#AC92EC",
                    "#EC87C0",
                    "#E6E9ED",
                    "#AAB2BD",
                    "#434A54",
                ],
                chart: {
                    animations: {
                        enabled: true,
                        easing: 'easin',
                        speed: 1000,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 1000
                        }
                    },
                    toolbar: {
                        show: false, // Display hamburger icon with saving options
                    },
                    events: {
                        dataPointSelection: (e, chartContext, config) => {
                            // you can call Vue methods now as "this" will point to the Vue instance when you use ES6 arrow function
                            // this.VueDemoMethod();
                            // console.log(chartContext);
                            // console.log(config);

                            // console.log(config.w.config.series[config.dataPointIndex])
                            // console.log(config.w.config.labels[config.dataPointIndex])
                        },
                        click: (event, chartContext, config) => {
                            // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
                            // console.log('Click'); // TODO: remove before PROD release
                            // console.log(chartContext); // TODO: remove before PROD release
                            // console.log(config); // TODO: remove before PROD release
                        }
                        // click: function(event, chartContext, config) {
                        //     console.log('clicked');
                        //     // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
                        // }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: "smooth"
                },
                tooltip: {
                    enabled: true,
                    x: {
                        format: "dd/MM/yy HH:mm"
                    }

                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return value + "%";
                            // WITH MONEY return formatMoney(value);
                        }
                    },
                },
                // responsive: [
                //     {
                //         breakpoint: 480,
                //         options: {
                //             chart: {
                //                 width: 200
                //             },
                //             legend: {
                //                 show: false
                //             }
                //         }
                //     },
                //     {
                //         breakpoint: 1280,
                //         options: {
                //             chart: {
                //                 width: 480
                //             },
                //         }
                //     }],
            }
        }),
        components: {
            apexchart: VueApexCharts,
        },
        methods: {

            getPoints() {
                const path = this.endpoint;
                axios.get(path)
                    .then((res) => {
                        this.chartOptions = {
                            series: res.data.series,
                            xaxis: res.data.xaxis,
                            colors: res.data.colors,
                            plotOptions: {
                                bar: {
                                    columnWidth: '45%',
                                    distributed: true
                                }
                            },
                        };
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
        },
        created(){
            this.getPoints();

            if(this.money) {
                // this.chartOptions.tooltip = {
                //     enabled: true,
                //     custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                //         let total = 0;
                //         for (let x of series) {
                //             total += x;
                //         }
                //         let selected = series[seriesIndex];
                //         let amount = "€ " + formatMoney(selected);
                //
                //         return amount + " (" + (selected / total * 100).toFixed(2) + "%)";
                //     }
                // }
            }
        }

    }

    function formatMoney(number) {
        return number.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' });
    }
</script>
